(function($) {

    if (!("querySelector" in document && "addEventListener" in window && "getComputedStyle" in window)) {
        return;
    }
    window.document.documentElement.className += " tiptop";

    var nav = document.querySelector("#nav"),
        navToggle = document.querySelector(".nav .skip");

    if (navToggle) {
        navToggle.addEventListener("click", function(e) {
            if (nav.className == "open") {
                nav.className = "";
            } else {
                nav.className = "open";
            }
            e.preventDefault();
        }, false);
    }

    // colorbox
    $(".colorbox").colorbox();

    $('.flexslider').flexslider({
        controlNav: false,
        directionNav: false,
        slideshowSpeed: 6000,
        animationSpeed: 1000,
        animation: "fade",
        useCSS: true
    });

})(jQuery);
